import "../js/algolia/searchPage.js"

const algoliaInputSearch = document.getElementById("alg-input");
const headStyle = document.head.appendChild(document.createElement("style"));
headStyle.id = "algolia-search-style";
headStyle.textContent = `
  .ais-RefinementList-count {
    display: none;
  }
`;

function toggleFacetsCounts(showFacetsCounts: boolean) {
  if (showFacetsCounts) {
    headStyle.textContent = `
      .ais-RefinementList-count {}
    `;
  } else {
    headStyle.textContent = `
      .ais-RefinementList-count {
        display: none;
      }
    `;
  }
}

if (algoliaInputSearch) {
  // Listen to Algolia's search input changes
  algoliaInputSearch.addEventListener("keyup", (e) => {
    const target = e.target as HTMLInputElement;
    toggleFacetsCounts(target.value.trim().length > 0);
  });

  // Also listen to search reset/clear
  algoliaInputSearch.addEventListener("search", (e) => {
    const target = e.target as HTMLInputElement;
    toggleFacetsCounts(target.value.trim().length > 0);
  });
}